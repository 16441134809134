@font-face {
  font-family: 'HarmoniaSans';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Regular.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Regular.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Regular.ttf')
      format('truetype');
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HarmoniaSans';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-SemiBd.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-SemiBd.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-SemiBd.ttf')
      format('truetype');
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'HarmoniaSans';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Bold.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Bold.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Bold.ttf')
      format('truetype');
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HarmoniaSans';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Black.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Black.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Black.ttf')
      format('truetype');
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HarmoniaSans-Condensed';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Cond.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Cond.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-Cond.ttf')
      format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HarmoniaSans-Condensed';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-SemiBdCond.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-SemiBdCond.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-SemiBdCond.ttf')
      format('truetype');
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HarmoniaSans-Condensed';

  src: url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-BoldCond.eot'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-BoldCond.woff')
      format('woff'),
    url('../assets/fonts/HarmoniaSansStd/HarmoniaSansStd-BoldCond.ttf')
      format('truetype');
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
